import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import ViewSale from "./ViewSale";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);

export default function SaleDetail(props) {

  const { id } = useParams();

  const classes = useStyles();
  return <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>SALE DETAIL</h4>
            </CardHeader>
            <CardBody>
                <Button onClick={() => props.history.goBack()} value="Cancel">
                  Cancel
                </Button>
                <Link to={"/admin/Sale/ProcessPayment/" + id}>
                  <Button color="info" className="like">
                    PROCESS PAYMENT
                  </Button>
                </Link>
              <ViewSale id={id} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
}

SaleDetail.propTypes = {
  history: PropTypes.object
};
