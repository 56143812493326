import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import ViewSale from "./ViewSale";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { GetSalePaymentMethods } from "enums/SalePaymentMethod.js";
import { posApi, cancelTokenSource } from "functions/apis";

const useStyles = makeStyles(styles);

export default function ProcessSalePayment(props) {
  const source = useRef();
  useEffect(() => {
    source.current = cancelTokenSource();
    return () => source.current.cancel("cancelled");
  }, []);

  const { id } = useParams();

  const [paymentMethod, setPaymentMethod] = React.useState(0);

  const handlePaymentMethodChanged = e => setPaymentMethod(e.target.value);
  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    posApi
      .post(
        "Sales/ProcessPayment", {
          id: id,
          paymentMethod: paymentMethod
        },
        {
          cancelToken: source.current.token
        }
      )
      .then(() => {
        if(paymentMethod === 3) {
          posApi
            .get(
              "Sales/" + id, {
                cancelToken: source.current.token
              }
            )
            .then(res => {
              window.location.href =
                process.env.REACT_APP_PAYMENT_URL +
                `/payment/${res.paymentID}?callbackUrl=${process.env.REACT_APP_SITE_URL}/admin/Sale/Create`;
            })
            .catch(error => console.log(error));
        }
        else {
          setRedirect(true);
        }
      })
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  }

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/Sale/Create"} />
  ) : (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>PROCESS SALE PAYMENT</h4>
            </CardHeader>
            <CardBody>
              <form>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selPaymentMethod"
                    className={classes.selectLabel}
                  >
                  Payment Method
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={paymentMethod}
                    onChange={handlePaymentMethodChanged}
                    inputProps={{
                      name: "selPaymentMethod",
                      id: "selPaymentMethod"
                    }}
                  >
                  {GetSalePaymentMethods().map(record => {
                    return (
                      <MenuItem
                        classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                        }}
                        value={record.key}
                        key={record.key}
                      >
                        {record.description}
                      </MenuItem>
                    );
                  })}
                  </Select>
                </FormControl>
                <Button onClick={() => props.history.goBack()} value="Cancel">
                  Cancel
                </Button>
                <Link to={"/admin/Sale/ProcessPayment/" + id}>
                  <Button
                    disabled={disabled}
                    onClick={handleSubmit}
                    color="info"
                    className="like"
                    type="submit"
                    value="PROCESS PAYMENT"
                  >
                    PROCESS PAYMENT
                  </Button>
                </Link>
              </form>
              <ViewSale id={id} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
  )
}

ProcessSalePayment.propTypes = {
  history: PropTypes.object
};
