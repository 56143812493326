import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import {
  posApi,
  cancelTokenSource
} from "functions/apis";

const useStyles = makeStyles(styles);

export default function CreateSale(props) {
  const source = useRef();
  const [categoryList, setCategoryList] = React.useState([]);
  useEffect(() => {
    source.current = cancelTokenSource();
    posApi
      .get("/Categories", {
        cancelToken: source.current.token,
        params: {
          filter: {
            searchString: null,
            sort: 0
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setCategoryList(data.records))
      .catch(error => console.log(error));

    return () => source.current.cancel("cancelled");
  }, []);

  const handleCategoryIDChanged = e => {
    setCategoryID(e.target.value);
    localStorage.setItem("configuration", JSON.stringify({
      categoryID: e.target.value
    }));
  }

  const [categoryID, setCategoryID] = React.useState(null);
  const classes = useStyles();
  return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>CONFIGURATION</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selCategory"
                  className={classes.selectLabel}
                >
                  Category
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={
                    categoryID === null ? "" : categoryID
                  }
                  onChange={handleCategoryIDChanged}
                  inputProps={{
                    name: "selCategory",
                    id: "selCategory"
                  }}
                >
                  {categoryList.map(record => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={record.id}
                        key={record.id}
                      >
                        {record.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
  );
}

CreateSale.propTypes = {
  history: PropTypes.object
};
