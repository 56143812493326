import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import CreateSale from "views/Sale/CreateSale";
import DeleteSale from "views/Sale/DeleteSale";
import PendingSaleList from "views/Sale/PendingSaleList";
import PaidSaleList from "views/Sale/PaidSaleList";
import ServedSaleList from "views/Sale/ServedSaleList";
import SaleDetail from "views/Sale/SaleDetail";
import ProcessSalePayment from "views/Sale/ProcessSalePayment";
import MarkSaleAsServed from "views/Sale/MarkSaleAsServed";

import { token } from "functions/Helper";

const sale = () => {
  let views = [];
  if (token["pos.sales.read"] === "allowed" || token.is_admin === "True") {
    views = views.concat([
      {
        path: "/Sale/Pending",
        name: "Pending",
        mini: "PE",
        component: PendingSaleList,
        layout: "/admin"
      },
      {
        path: "/Sale/Paid",
        name: "Paid",
        mini: "PA",
        component: PaidSaleList,
        layout: "/admin"
      },
      {
        path: "/Sale/Served",
        name: "Served",
        mini: "SE",
        component: ServedSaleList,
        layout: "/admin"
      },
    ]);
  }
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Sales",
        icon: MonetizationOnIcon,
        state: "saleCollapse",
        views: views
      }
    ];
  return [];
}

const saleHidden = () => {
  let views = [];
  if (token["pos.sales.read"] === "allowed" || token.is_admin === "True") {
    views = views.concat([
      {
        path: "/Sale/Detail",
        name: "Sale Detail",
        component: SaleDetail,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
    ]);
  }
  if (token["pos.sales.write"] === "allowed" || token.is_admin === "True") {
    views = views.concat([
      {
        path: "/Sale/Create",
        name: "Create Sale",
        component: CreateSale,
        layout: "/admin"
      },
      {
        path: "/Sale/Delete",
        name: "Delete Sale",
        component: DeleteSale,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
    ]);
  }
  if (token["pos.sales.process_payment"] === "allowed" || token.is_admin === "True") {
    views = views.concat([
      {
        path: "/Sale/ProcessPayment",
        name: "Process Sale Payment",
        component: ProcessSalePayment,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  }
  if (token["pos.sales.mark_as_served"] === "allowed" || token.is_admin === "True") {
    views = views.concat([
      {
        path: "/Sale/MarkAsServed",
        name: "Mark Sale as Served",
        component: MarkSaleAsServed,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  }
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Sale Hidden",
        state: "saleCollapse",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { sale, saleHidden };