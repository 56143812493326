import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import {
  posApi,
  cancelTokenSource
} from "functions/apis";

const useStyles = makeStyles(styles);

export default function CreateSale(props) {
  const source = useRef();
  const [disabled, setDisabled] = React.useState(false);
  const [productList, setProductList] = React.useState([]);
  const [redirection, setRedirection] = React.useState({
    redirect: false,
    id: null
  });
  const [product, setProduct] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [isAddingLine, setIsAddingLine] = React.useState(false);
  useEffect(() => {
    let configuration = JSON.parse(localStorage.getItem("configuration"));
    source.current = cancelTokenSource();
    posApi
      .get("/Products", {
        cancelToken: source.current.token,
        params: {
          filter: {
            searchString: null,
            sort: 0,
            categoryID: configuration !== null ? configuration.categoryID : null
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setProductList(data.records))
      .catch(error => console.log(error));

    return () => source.current.cancel("cancelled");
  }, []);

  let defaultLine = {
    productID: null,
    note: null,
    quantity: 0,
    unitPrice: 0,
    total: 0
  };

  const [line, setLine] = React.useState({
    ...defaultLine
  });

  const [state, setState] = React.useState({
    lines: []
  });

  const addingLine = () => setIsAddingLine(true);

  const addLine = () => {
    if(line.quantity <= 0) {
      alert("Quantity must be greater than 0.");
    }
    else {
      setState({
        lines: state.lines.concat([line])
      });
      setLine({
        ...defaultLine
      });
      setTotal(total + line.total);
      setIsAddingLine(false);
    }
  }

  const removeLine = (index) => {
    let line = state.lines[index];
    setTotal(total - line.total);
    let lines = [
      ...state.lines
    ];
    lines.splice(index,1);
    setState({
      lines: lines
    });
  }

  const handleProductIDChanged = e => {
    let selectedID = e.target.value;
    let product = productList.find(p => p.id === selectedID);
    setProduct(product);
    setLine({
      ...line,
      productID: e.target.value,
      unitPrice: product.price,
      total: product.price * line.quantity
    });
  }
  const handleNoteChanged = e => setLine({
    ...line,
    note: e.target.value === "" ? null : e.target.value
  });

  const handleQuantityChanged = e => {
    let newQuantity = parseInt(e.target.value);
    if (newQuantity !== line.quantity) setLine({
      ...line,
      quantity: newQuantity,
      total: line.unitPrice * newQuantity
    });
    e.preventDefault();
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    posApi
      .post(
        "/Sales", state,
        {
          cancelToken: source.current.token
        }
      )
      .then((res) => setRedirection({
        redirect: true,
        id: res
      }))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  }

  const classes = useStyles();
  return redirection.redirect ? (
    <Redirect to={"/admin/Sale/Detail/" + redirection.id} />
  ) : (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>CREATE SALE</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <br />
              <Button
                disabled={disabled || total === 0}
                onClick={handleSubmit}
                color="rose"
                type="button"
                value="Create Sale"
              >
                Create Sale
              </Button>
              <CustomInput
                labelText="Total"
                id="numTotal"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: "SGD " + total.toLocaleString()
                }}
              />
              <br />
              {
                !isAddingLine &&
                <Button
                  onClick={addingLine}
                  color="rose"
                  type="button"
                  value="Add Line"
                >
                  <AddIcon />Add Line
                </Button>
              }
              {
                isAddingLine &&
                (
                  <>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selProduct"
                      className={classes.selectLabel}
                    >
                      Product
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={
                        line.productID === null ? "" : line.productID
                      }
                      onChange={handleProductIDChanged}
                      inputProps={{
                        name: "selProduct",
                        id: "selProduct"
                      }}
                    >
                      {productList.map(record => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={record.id}
                            key={record.id}
                          >
                            {record.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <CustomInput
                    labelText="Quantity"
                    id="numQuantity"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      onChange: handleQuantityChanged,
                      value: line.quantity,
                      inputMode: "numeric"
                    }}
                  />
                  <CustomInput
                    labelText="Note"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleNoteChanged,
                      value: line.note === null ? "" : line.note
                    }}
                  />
                  {product !== null && (
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="Currency"
                          disabled
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: "SGD"
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="Unit Price"
                          id="unitPrice"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: product.price
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="Total"
                          id="total"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: (product.price * line.quantity).toLocaleString()
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  )}
                  <Button
                    disabled={disabled}
                    onClick={addLine}
                    color="rose"
                    type="button"
                    value="Add"
                  >
                    Add
                  </Button>
                  </>
                )
              }
              {
                state.lines.map((line, index) => {
                  let product = productList.find(p => p.id === line.productID);
                  return <GridItem md={12} lg={12} key={index}>
                    <Card>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={8} md={8} lg={8}>
                          <p><b>{product.name}</b></p>
                          <p>{"SGD " + line.unitPrice.toLocaleString() + " x " + line.quantity + " = "}<b>{"SGD " + line.total.toLocaleString()}</b></p>
                          {line.note !== null &&
                          (
                            <p>{line.note}</p>
                          )}
                        </GridItem>
                        <GridItem xs={4} md={4} lg={4}>
                          <Button
                            disabled={disabled}
                            onClick={() => removeLine(index)}
                            color="rose"
                            type="button"
                            value="Remove"
                          >
                            <DeleteIcon />
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                  </GridItem>
                })
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

CreateSale.propTypes = {
  history: PropTypes.object
};
