import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import ViewSale from "./ViewSale";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { posApi, cancelTokenSource } from "functions/apis";

const useStyles = makeStyles(styles);

export default function DeleteSale(props) {
  const source = useRef();
  useEffect(() => {
    source.current = cancelTokenSource();
    return () => source.current.cancel("cancelled");
  }, []);

  const { id } = useParams();
  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    posApi
      .delete(
        "Sales/" + id, {
          cancelToken: source.current.token
        }
      )
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  }

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/Sale/Pending"} />
  ) : (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>DELETE SALE</h4>
            </CardHeader>
            <CardBody>
              <form>
                <Button onClick={() => props.history.goBack()} value="Cancel">
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  disabled={disabled}
                  color="info"
                  className="like"
                  type="submit"
                  value="DELETE"
                >
                DELETE
                </Button>
              </form>
              <ViewSale id={id} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
  )
}

DeleteSale.propTypes = {
  history: PropTypes.object
};
