import axios from "axios";

function getHeaders() {
  let headers = {};
  if (localStorage.getItem("Authorization") !== null)
    headers = {
      ...headers,
      Authorization: localStorage.getItem("Authorization")
    };
  return headers;
}

const cancelTokenSource = () => axios.CancelToken.source();

const counterApi = axios.create({
  baseURL: process.env.REACT_APP_COUNTER_API_URL,
  headers: getHeaders()
});

const posApi = axios.create({
  baseURL: process.env.REACT_APP_POS_API_URL,
  headers: getHeaders()
});

const responseInterceptor = response => {
  return response.data.data;
};

const errorResponseHandler = error => {
  if (error.message !== "cancelled") {
    if (error.response !== undefined) {
      if (error.response.status === 401) return Promise.reject(error);

      if (error.response.data !== undefined)
        alert(JSON.stringify(error.response.data.exception.message));
      else alert(JSON.stringify(error.response));
    } else {
      alert(JSON.stringify(error));
    }
  }
  return Promise.reject(error);
};

counterApi.interceptors.response.use(responseInterceptor, errorResponseHandler);
posApi.interceptors.response.use(responseInterceptor, errorResponseHandler);

export {
  counterApi,
  posApi,
  cancelTokenSource
};