import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { posApi, cancelTokenSource } from "functions/apis";

export default function ViewSale(props) {
  const source = useRef();
  useEffect(() => {
    source.current = cancelTokenSource();
    return () => source.current.cancel("cancelled");
  }, []);

  const { id } = props;

  const [sale, setSale] = React.useState(null);
  const [lines, setLines] = React.useState([]);
  useEffect(() => {
    posApi
      .get("/Sales/" + id, {
        cancelToken: source.current.token
      })
      .then(data => setSale(data))
      .catch(error => console.log(error));
      
    posApi
    .get("/Sales/" + id + "/Lines", {
      cancelToken: source.current.token
    })
    .then(data => setLines(data.records))
    .catch(error => console.log(error));
    
  }, [id]);

  return (
    sale !== null && (
      <>
        <CustomInput
          disabled
          labelText="No"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: sale.no
          }}
        />
        <CustomInput
          disabled
          labelText="Total"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: "SGD " + sale.total.toLocaleString()
          }}
        />
        {
          lines.map(record => {
            return <GridItem md={12} lg={12} key={record.sequence}>
              <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={8} md={8} lg={8}>
                    <p><b>{record.product.name}</b></p>
                    <p>{"SGD " + record.unitPrice.toLocaleString() + " x " + record.quantity + " = "}<b>{"SGD " + record.total.toLocaleString()}</b></p>
                    {record.note !== null &&
                    (
                      <p>{record.note}</p>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            </GridItem>
          })
        }
      </>
    )
  );
}

ViewSale.propTypes = {
  id: PropTypes.string
};
