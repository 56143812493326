import LoginPage from "views/Pages/LoginPage.js";
import Configuration from "views/Pages/Configuration.js";
import { dashboard } from "routes/dashboard";
import { sale, saleHidden } from "routes/sale";
import SettingsIcon from '@material-ui/icons/Settings';

var dashRoutes = []
  .concat(dashboard())
  .concat(sale())
  .concat(saleHidden())
  .concat([
    {
      path: "/Configuration",
      name: "Configuration",
      icon: SettingsIcon,
      component: Configuration,
      layout: "/admin"
    }
  ])
  .concat([
    {
      collapse: true,
      name: "Hide",
      state: "hideCollapse",
      hideInSidebar: true,
      views: [
        {
          path: "/login",
          name: "Login",
          component: LoginPage,
          layout: "/auth"
        }
      ]
    }
  ]);
export default dashRoutes;