export function GetSalePaymentMethods() {
    return [
      {
        key: 0,
        description: "Cash"
      },
      {
        key: 1,
        description: "Pay Now"
      },
      {
        key: 2,
        description: "Debit Card"
      },
      {
        key: 3,
        description: "Grab"
      }
    ];
  }
  
  export function GetSalePaymentMethodsDescription(value) {
    let salePaymentMethod = GetSalePaymentMethods().find(
      item => item.key === value
    );
    return salePaymentMethod.description;
  }
  